import React from "react";

import { Container, Row, Col } from "reactstrap";
import Link from "../components/link";
import Button from "../components/btn";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { FaGithub, FaBolt, FaHome, FaWrench, FaWarehouse, FaBroom } from "react-icons/fa";
import Form from "../components/form";
import Slider from "../components/slider";
import Box from "../components/box";
import Hr from "../components/hr";
import Benefits from "../components/benefits";
import styled from "styled-components";
import HomeFeatures from "../components/homeFeatures";
import Testimonials from "../components/testimonials";
import { SliderContainer } from "../components/slider";
import logo from "../assets/images/logo.png";
import fabric from "../assets/images/fabric.png";
import "../components/styles.scss";

let StyledBackground = styled.div`
  background: linear-gradient(to bottom, #f9fbfd 0, #fff 100%);
`;

let Service = ({ title, Icon = FaHome, link = "" }) => (
  <Col xs={12} md={4} className="mb-3">
    <Link to={link} style={{ color: "black" }}>
      <Box>
        <Icon size={30} />
        <h4 className="mt-3">{title}</h4>
      </Box>
    </Link>
  </Col>
);

let Index = () => (
  <Layout>
    <SEO title="Home" />

    {/*<SliderContainer>*/}
    <div className="text-center" style={{
      paddingTop: "2.0rem",
      paddingBottom: "2.0rem"
    }}>
      {/*<div style={{*/}
      {/*  display: "flex",*/}
      {/*  justifyContent: "center",*/}
      {/*  paddingLeft: "1rem",*/}
      {/*  paddingRight: "1rem"*/}
      {/*}}>*/}
      {/*</div>*/}
      <div className="row">
        <div className="col">
          <img className={"img-fluid logo"} style={{ paddingBottom: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }} src={logo} />
        </div>
      </div>
      <h1 className="pb-0 mb-0">Saliba's Rug Cleaners LLC</h1>
      <a className="h5 mt-0 pt-0" href="https://goo.gl/maps/49hP3KoV8JNWjv8Z6">
        69 Hillside Ave, Bangor, ME
      </a>
      <p className="mt-2">
        <h2 className={"h6"}>Rug Cleaning & Repair Services in Bangor, ME</h2>
      </p>
      {/*<Link to="/services/">*/}
      {/*  <Button type="button" className={"btn btn-lg btn-success"}*/}
      {/*          style={{*/}
      {/*            color: "#FFF",*/}
      {/*            backgroundColor: "#28a745",*/}
      {/*            borderColor: "#28a745"*/}
      {/*          }}*/}
      {/*  >*/}
      {/*    OUR SERVICES*/}
      {/*  </Button>*/}
      {/*</Link>*/}
      <a href="tel:2079478876">
        <Button type="button" className={"btn btn-lg btn-success"}
                style={{
                  color: "#FFF",
                  backgroundColor: "#28a745",
                  borderColor: "#28a745"
                }}
        >
          CALL NOW
        </Button>
      </a>
    </div>
    {/*</SliderContainer>*/}
    <div className={"py-4"} style={{ backgroundColor: "white" }}>
      <Container style={{ maxWidth: 620 }}>
        <div className="text-center mb-4">
          <h4>
            Saliba's Rug Cleaners is a family owned oriental rug cleaning & repair business that has been serving the Bangor area for over
            70 years!
          </h4>
          <p className="text-muted h6">
            We care about your rugs. Clean rugs last longer.
          </p>
          <p className="text-muted h6">
            <i>
              Clean rugs for a healthy home.
            </i>
          </p>

        </div>
      </Container>

      <hr />

      <Container>
        <div className={"mb-4"}>
          <h3>Our Location in Bangor, ME</h3>
          <p>We are <strong>open 8AM - 4PM</strong> from Monday to Friday. Come in today for a free quote!</p>

          <div id="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11324.87516022668!2d-68.7706831!3d44.7967296!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b1757948b5cd789!2sSaliba&#39;s+Rug+Cleaners%2CLLC!5e0!3m2!1sen!2sus!4v1530558351519"
              width="auto" height="auto" frameBorder="0" style="border:0" allowFullScreen></iframe>
          </div>
          <a target={"_blank"} href="https://goo.gl/maps/49hP3KoV8JNWjv8Z6">
            <Button type="button" href="" className={"btn-lg btn-block my-1"}>
              GET DIRECTIONS
            </Button>
          </a>
        </div>
        <hr />

        {/*<div>*/}
        {/*  <h2 className="text-left">Services</h2>*/}
        {/*  <Row>*/}
        {/*    <Service link={"/services/repairs"} title="Repairs" Icon={FaWrench} />*/}
        {/*    <Service link={"https://shop.salibasrugcleaners.com/"} title="Rug Inventory" Icon={FaWarehouse} />*/}
        {/*    <Service link={"/services/deep-cleaning"} title="Cleaning" Icon={FaBroom} />*/}
        {/*  </Row>*/}
        {/*</div>*/}
      </Container>
    </div>
    {/*<div className="text-center py-5">*/}
    {/*  <Button*/}
    {/*    to="https://github.com/jeremylynch/gatsby-strapi-starter"*/}
    {/*    className="btn btn-primary btn-lg"*/}
    {/*  >*/}
    {/*    <FaGithub className="mr-1" />*/}
    {/*    View on Github*/}
    {/*  </Button>*/}
    {/*</div>*/}
    <StyledBackground>
      {/*<Benefits />*/}
      <div className="py-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Box style={{ textAlign: "left" }}>
                <h3 className="text-center">Let's get in touch!</h3>
                <Hr />
                <Form />
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledBackground>
    {/*<HomeFeatures />*/}
    {/*<Testimonials/>*/}
  </Layout>
);

export default Index;
